// define light theme
$light-primary:#192f41;
$light-warn:#AA2213;
$light-theme:#ECEBE5;
$light-primary-palette: (
    50: #e3efff,
    100: #c3d8ea,
    200: #a6bcd2,
    300: #86a1bb,
    400: #6e8ca8,
    500: #567897,
    600: #496a86,
    700: #39566f,
    800: #2b4459,
    900: #192f41,
    contrast: (50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
    )
);


$light-accent-palette: (
    50: #f4ebfe,
    100: #ec2dfd,
    200: #cfabfd,
    300: #bb86fc,
    400: #aa67f9,
    500: #994af1,
    600: #8e44ea,
    700: #803be0,
    800: #7335d8,
    900: #192f41,
    contrast: (50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
    )
);

$light-warn-palette: (
    50: #fdeaee,
    100: #fbcad2,
    200: #ea959b,
    300: #de6c74,
    400: #e84853,
    500: #ed323b,
    600: #de2839,
    700: #cc1d33,
    800: #bf152c,
    900: #b00020,
    contrast: (50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
    )
);

$pr1-light-primary: mat.define-palette($light-primary-palette, 900);
$pr1-light-accent: mat.define-palette($light-accent-palette, 900);
$pr1-light-warn: mat.define-palette($light-warn-palette, 900);
$pr1-light-theme: mat.define-light-theme((color: (primary: $pr1-light-primary,
                accent: $pr1-light-accent,
                warn: $pr1-light-warn,
            ),
        ));




// define dark theme
$dark-primary-palette: (
    50: #292929,
    100: #4b4b4b,
    200: #6a6a6a,
    300: #7f7f7f,
    400: #a9a9a9,
    500: #c7c7c7,
    600: #e9e9e9,
    700: #f2f2f2,
    800: #f7f7f7,
    900: #fcfcfc,
    contrast: (50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
    )
);

$dark-accent-palette: (
    50: #5f27ca,
    100: #7335d8,
    200: #803be0,
    300: #8e44ea,
    400: #994af1,
    500: #aa67f9,
    600: #bb86fc,
    700: #cfabfd,
    800: #ec2dfd,
    900: #f4ebfe,

    contrast: (50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
    )
);

$dark-warn-palette: (
    50: #fdeaee,
    100: #fbcad2,
    200: #ea959b,
    300: #de6c74,
    400: #e84853,
    500: #ed323b,
    600: #de2839,
    700: #cc1d33,
    800: #bf152c,
    900: #b00020,
    contrast: (50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
    )
);

$pr1-dark-primary: mat.define-palette($dark-accent-palette, 900);
$pr1-dark-accent: mat.define-palette($light-accent-palette, 900);
$pr1-dark-warn: mat.define-palette($dark-warn-palette, 900);
$pr1-dark-theme: mat.define-dark-theme((color: (primary: $pr1-dark-primary,
                accent: $pr1-dark-accent,
                warn: $pr1-dark-warn,
            ),
        ));