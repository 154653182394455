@use "@angular/material" as mat;
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();
@import "./variables.scss";
// @import './gridster-content.scss';
@import "./button";
@import "./offer";

// theme
// primary #121212
// accent: #bb86fc

// dark theme base color: #292929

// define styles for theme switching

/* Fonts */
@font-face {
  font-family: "nexablack";
  src: url("../assets/fonts/nexa-black-webfont.woff2") format("woff2"),
    url("../assets/fonts/nexa-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "nexabold";
  src: url("../assets/fonts/nexa-bold-webfont.woff2") format("woff2"),
    url("../assets/fonts/nexa-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "nexaregular_italic";
  src: url("../assets/fonts/nexa-regularitalic-webfont.woff2") format("woff2"),
    url("../assets/fonts/nexa-regularitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "nexaregular";
  src: url("../assets/fonts/nexa-regular-webfont.woff2") format("woff2"),
    url("../assets/fonts/nexa-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.mat-typography h1,
.welcome h1 {
  font-family: "nexablack";
  font-size: 24px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5 {
  font-family: "nexaregular" !important;
  letter-spacing: normal;
}

.pr1-light-theme {
  @include mat.all-component-themes($pr1-light-theme);

  .mat-mdc-row:hover {
    background-color: #fafafa;
  }

  .mat-mdc-snack-bar-action {
    color: #f4ebfe;
  }

  .mat-mdc-raised-button[disabled][disabled] {
    background: #e6e6e6;
  }

  .mat-mdc-card {
    background: white;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }

  .mat-mdc-header-cell {
    color: rgba(0, 0, 0, 0.54);
  }

  .mat-mdc-menu-content,
  .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
    font-size: 14px !important;
    font-weight: 400 !important;
    letter-spacing: inherit !important;
    line-height: 0;
  }

  .mat-mdc-option.mdc-list-item {
    font-size: 14px;
    line-height: 18px;
  }

  .mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: #fff !important;
  }

  .mat-drawer-content.mat-sidenav-content {
    background: #ecebe5;
  }

  .sidenav.mat-drawer,
  .sidenav.mat-sidenav .mat-expansion-panel-body {
    background-color: $light-primary !important;
  }

  mat-sidenav mat-expansion-panel-header {
    background-color: $light-primary !important;
  }

  .mat-accent.mat-mdc-raised-button {
    background-color: $light-primary;
  }

  .mat-mdc-raised-button.mat-warn {
    background-color: $light-warn;
  }

  .mdc-button.mat-unthemed.mat-mdc-button-base,
  .mat-mdc-outlined-button {
    background-color: $light-theme;
    color: #000;
  }

  .link-btns button span {
    color: $light-primary;
  }

  .mdc-snackbar__action.mdc-button.mat-unthemed.mat-mdc-button-base {
    background: none !important;
  }

  .xng-breadcrumb-list,
  .coredata,
  .mat-sidenav .mdc-list-item__primary-text,
  .mat-mdc-menu-item-text,
  .profile-image p {
    font-family: "nexaregular" !important;
  }

  .mdc-button.mat-unthemed.mat-mdc-button-base.mat-mdc-tooltip-trigger {
    background-color: #fff;
  }

  .mat-mdc-button:not(:disabled),
  .mat-mdc-outlined-button:not(:disabled) {
    color: $light-primary;
  }

  // .mat-elevation-z9, .pr1-light-theme .mat-mdc-elevation-specific.mat-elevation-z9{
  //   position: relative;
  //   left: 15px;
  // }
}

.dashboard-card h2 {
  color: $light-primary;
}

.pr1-dark-theme {
  @include mat.all-component-themes($pr1-dark-theme);

  .mat-mdc-row:hover {
    background-color: #212121;
  }
}

// reset default browser styles
* {
  box-sizing: border-box;
}

html,
body {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
}

//helper styles
[hidden] {
  display: none !important;
}

// menu styles
.menu-spacer {
  flex: 1 1 auto;
}

//table styles
table {
  width: 100%;
  table-layout: fixed;
}

th,
td {
  overflow: hidden;
  width: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

// global column widths
.mat-column-select {
  width: 75px;
}

.mat-column-Actions {
  width: 90px;
}

// form fields with no padding
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-no-padding .mat-form-field-wrapper,
.mat-mdc-form-field-no-padding .mat-mdc-form-field-wrapper {
  margin-bottom: -1.25em !important;
}

// list pages with no records to display
.no-records {
  text-align: center;
}

//button styles
button {
  fa-icon {
    margin-right: 8px;
  }
}

// ellipsis utils
.ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis-3-line {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.actions-bar {
  margin: 0 -15px 4px 0;
  margin-bottom: 4px !important;

  @media only screen and (max-width: 1100px) {
    display: inline-block !important;
  }
}

// material overrides

// action buttons on record detail pages
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/

// .templateContainer {
//   >.main-img {
//       max-height: calc(100vh - 300px);
//   }
// }

// .templateContainer.contain-img,
// .templateContainer.fill-img {
//   >.offer {
//       max-height: calc(100vh - 300px);

//       .offer-image {
//           height: calc(100vh - 300px);

//           video {
//               max-width: 50vw;
//           }
//       }
//   }
// }

.link-btns {
  border: none !important;

  button {
    span {
      padding: 0 !important;
      border: none !important;
    }

    &:hover {
      background: none !important;
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-button-toggle {
    border: none !important;
    padding: 0 30px 0 0;
    background: none !important;
    color: $light-primary !important;

    &:hover {
      background: none !important;
      --mat-standard-button-toggle-hover-state-layer-opacity: 0 !important;
    }
  }
}

th.mat-mdc-header-cell:last-of-type,
td.mat-mdc-cell:last-of-type,
td.mat-mdc-footer-cell:last-of-type {
  padding-right: 0 !important;
}

table {
  width: 100%;
}

.mat-column-Rank {
  width: 6% !important;
}

.mat-column-Actions {
  width: 10% !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

/*Side Menu Fix Starts, Let's have different classes when rebranding the site instead of mdc classes*/

.mat-sidenav {
  width: 205px !important;

  .mat-mdc-list-item {
    height: auto !important;
    vertical-align: middle;
    padding: 8px 16px !important;
    margin: 10px 0;

    /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
    .mat-mdc-list-item-content {
      align-items: self-start !important;
    }
  }

  .mat-mdc-nav-list {
    margin-left: 0 !important;
    padding-top: 0 !important;
  }

  .mat-expansion-panel-header.mat-expanded {
    height: 48px !important;
    font-size: 16px;
    font-family: "nexaregular";

    .mat-mdc-list-base {
      padding-top: 0 !important;
    }
  }

  .mat-expansion-indicator::after {
    color: #fff !important;
  }

  .mat-expansion-panel-body {
    padding: 0 24px 0 !important;
  }

  .mdc-list-item__primary-text {
    font-size: 16px !important;
  }

  .mat-expansion-panel-header {
    font-size: 16px;
    font-weight: normal;
    font-family: "nexaregular";
  }
}

.mat-mdc-menu-item .mat-mdc-menu-item-text {
  padding-right: 10px;
}

/*Side Menu Fix Ends*/

.mat-mdc-form-field {
  min-width: initial !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.125;
  letter-spacing: normal !important;
  padding-bottom: 2em !important;
}

.mdc-data-table__header-cell {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.mat-mdc-form-field-subscript-wrapper {
  position: absolute !important;
  width: auto !important;
}

.mdc-text-field--outlined {
  color: rgba(0, 0, 0, 0.12) !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 24px;
  box-sizing: content-box;
  .mat-mdc-dialog-title, .mdc-dialog__content, .mdc-dialog__actions{
    //padding: 0;
    line-height: 24px !important;
  }
}

.mat-mdc-dialog-container {
  min-width: 400px !important;
  .mat-mdc-form-field{
    padding-bottom: 0 !important;
  } 
  .mat-mdc-table{
    box-shadow: none;
  }
}

.mat-mdc-dialog-content {
  .mat-mdc-form-field {
    display: inherit;
    text-align: left;
    line-height: 2;
  }
}

.mat-mdc-dialog-surface {
  max-height: 80vh !important;
}

.mat-mdc-text-field-wrapper {
  padding-bottom: 1.34375em;
  margin: 0.25em 0;
  z-index: 1;
}

.mat-mdc-tab-link {
  flex-grow: 0 !important;
  opacity: 0.6;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  min-width: 160px !important;
}

.mat-mdc-card {
  border-radius: 4px !important;
  border: none !important;
}

.mat-mdc-tab-link-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.mat-mdc-select-value-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
}

.mat-card-actions-details {
  margin-bottom: 8px !important;
  width: 100% !important;
}

.mdc-button {
  margin: 0 8px 0 8px !important;
}

.actions-bar .mdc-button {
  margin: 0 16px 0 0 !important;
}

.actions-bar .mat-mdc-form-field-infix {
  min-height: 20px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.actions-bar .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 50% !important;
}

.actions-bar .mdc-text-field__input {
  position: relative;
  top: 4px;
}

.actions-bar .mat-mdc-form-field {
  padding: 0 !important;
}

.mat-mdc-button .mdc-button__label,
.mat-mdc-unelevated-button .mdc-button__label,
.mat-mdc-raised-button .mdc-button__label,
.mat-mdc-outlined-button .mdc-button__label {
  letter-spacing: initial !important;
}

.mat-mdc-table {
  table-layout: fixed !important;
}

.mdc-text-field .mdc-floating-label {
  letter-spacing: normal !important;
  font-size: 14px !important;
}

.mat-mdc-form-field-infix {
  padding-top: 15px !important;
  padding-bottom: 8px !important;
  min-height: 50px !important;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  position: relative !important;
  top: 27px !important;
  padding: 4px 0 0 0 !important;
}

.mat-mdc-card-md-image {
  object-fit: contain;
}

.mat-mdc-list-item-unscoped-content.mdc-list-item__primary-text {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  height: inherit;
}

// .mat-mdc-text-field-wrapper{
//   --mdc-outlined-text-field-outline-width: 0 !important;
//     border: 1px solid rgba(0, 0, 0, 0.12) !important;
//     border-radius: 4px !important;
// }
// .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input{
//   color: rgba(0, 0, 0, 0.12) !important;
// }
.mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
  padding: 6px 4px;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:focus:checked~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:focus:indeterminate~.mdc-checkbox__background {
  border-color: $light-primary !important;
  background-color: $light-primary !important;
}

// .mat-mdc-checkbox.card-checkbox .mdc-checkbox__background {
//   background: #fff !important;
// }

.no-ellipsis {
  text-overflow: inherit !important;
}

.mat-column-Actions {
  button {
    margin: 0 !important;
    padding: 0;
    min-width: inherit;
    background: none;
  }
}

.mat-tree-node {
  .mdc-button.mat-mdc-button.mat-accent {
    line-height: 48px;
    height: 48px;
  }
}

.styled-card {
  padding: 15px;

  .mat-mdc-card-content {
    padding: 0;
  }
}

.actions-bar.inner {
  align-items: center;
}

.bg-white {
  background: #fff;
  margin: 0px 0;
  padding: 16px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  .mat-button-toggle-group {
    margin: 0 16px 0 0;
  }
}

.marginBottom {
  margin-bottom: 20px;
}

.event-name {
  color: $light-primary;
  font-size: 18px !important;
}

.mat-mdc-text-field-wrapper {
  flex: initial !important;
}

.mat-drawer-container {
  background-color: none !important;
}

.pr1-light-theme .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  //top: 50% !important;
}

.pr-offer-preview {
  .price-line {
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    padding: 0 0 5px 0;
  }

  .head-line {
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 1.25rem;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .body-copy {
    font-size: 14px;
    padding: 0 0 0 0;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .retail-price-line {
    font-size: 18px;
    font-weight: 500;
  }
}

.version-codes {
  margin-bottom: -15px;
}

.pr-coupon-preview {
  display: flex;

  .pr-coupon-image-content {
    text-align: center;
    background-color: #f4f4f4;
    position: relative;
    margin-bottom: 15px;
    border-radius: 4px;
    margin-left: 15px;
    padding: 15px;
    width: 156px;
    max-height: 166px;
    min-width: 156px;

    img {
      max-width: 100%;
      height: auto;
      object-fit: contain;
      max-height: 150px;
    }
  }

  .pr-coupon-content {
    margin-right: 16px;
    margin: 30px 0 0 15px;
  }

  .pr-coupon-summary {
    font-size: 16px;
    font-weight: 500;
    display: block;
  }

  .pr-coupon-description {
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    line-height: 16px;
    padding-right: 10px !important;
  }

  .pr-coupon-validity {
    position: relative;
    top: -4px;
  }

  .pr-coupon-description,
  .pr-coupon-validity,
  .pr-coupon-featured-text {
    font-size: 13px;
    padding: 0 0 2px 0;
    display: block;
  }

  .pr-coupon-promo {
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    padding: 0 0 2px 0;
    display: block;
  }
}

.CouponDetails {
  .pr-coupon-preview {
    display: inline-block;
    width: 100%;
  }

  .pr-coupon-image-content {
    width: 100%;
    top: 0;
    margin-bottom: 25px;
    margin: 0 0 25px 0;
  }
}

/*Status Styling*/

.arrows {
  margin: 0 0 0 0;
  display: grid;
  text-align: center;

  .statusstatus-blank {
    background: #e8f5e9;
    color: #1b5e20;
  }

  .statusApproved {
    background: #e8f5e9;
    color: #1b5e20;
  }

  .statusPending {
    background: #fff9d5;
    color: #806c0a;
  }

  .statusSuppressed {
    background: #ffebee;
    color: #b0001f;

    &.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
      background: #ffebee;
    }
  }

  .statusAPPROVED {
    background: #e8f5e9;
    color: #1b5e20;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    .number {
      color: #1b5e20;
    }
  }

  .statusPENDING {
    background: #fff9d5;
    color: #806c0a;

    .number {
      color: #806c0a;
    }
  }

  .statusSUPPRESSED {
    background: #ffebee;
    color: #b0001f;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    .number {
      color: #b0001f;
    }
  }

  .nextButtons.statusAPPROVED {
    border-left: 2px solid #1b5e20 !important;

    &.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
      background: #e8f5e9;
    }
  }

  .nextButtons.statusPENDING {
    border-left: 2px solid #806c0a !important;

    &.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
      background: #fff9d5;
    }
  }

  .nextButtons.statusSUPPRESSED {
    border-left: 2px solid #b0001f !important;

    &.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
      background: #ffebee;
    }
  }

  .statusApproved {
    background: #e8f5e9;
    color: #1b5e20;
  }

  .statusPending {
    background: #fff9d5;
    color: #806c0a;
  }

  .statusSuppressed {
    background: #ffebee;
    color: #b0001f;
  }

  .multiple-toggle-btns {
    box-shadow: 0px 1px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  }

  .nextButtons {
    border-left: 2px solid black;
    align-items: center;
    display: inline-block;
    font-size: 14px;
    color: #000;
    min-width: 95px;
    height: 70px;
    padding: 14px 10px 10px 10px;
    vertical-align: middle;

    .mat-button-toggle-label-content {
      padding: 0;
      --mat-standard-button-toggle-height: auto;
      display: flex;
      align-items: center;

      fa-icon {
        margin-right: 0;

        svg {
          color: #797979;
          height: 1.4em;
          position: relative;
          left: 4px;
        }
      }
    }

    &.mat-button-toggle-checked::before {
      content: "";
      background-color: rgba(0, 0, 0, 0.08);
      width: 100%;
      display: block;
      position: absolute;
      height: 64px;
      top: 0px;
      left: 0;
    }

    &.mat-button-toggle-checked {
      .number-text {
        font-weight: bold;
      }
    }
  }

  .mt-18 {
    margin-top: 18px;
  }

  .number {
    font-size: 16px;
    font-weight: bold;
  }
}

.users-table {
  .mat-mdc-header-cell {
    font-size: 14px !important;
    font-weight: 500;
  }

  td {
    .mat-mdc-raised-button {
      text-align: left !important;
      box-shadow: none !important;
      width: 122px;
      background: none !important;

      .mat-icon {
        float: right;
        position: absolute;
        top: 10px;
        right: 4px;
      }
    }
  }
}

.pr-coupon-barcode {
  border: 1px solid #333;
  padding: 5px;
  height: 60px;
  width: 94%;
  margin: 10px 0;
}

.mat-toolbar {
  div {
    text-transform: capitalize;
    font-family: "nexablack";
    font-size: 24px;
  }

  .mdc-icon-button {
    padding: 0 !important;
  }
}

.profile-image p {
  margin: 20px 0;
}

.nextButtons {
  height: auto !important;
}

.mat-mdc-menu-item .mat-icon {
  margin-left: 16px;
  margin-right: 0;
}

.mat-mdc-table {
  // width: 100%;

  &.resizing {
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: col-resize;
  }

  .mat-mdc-header-cell {
    &:not(:last-child) {
      .resize-holder {
        cursor: col-resize;
        position: absolute;
        top: 0;
        right: 0;
        height: 55px;
        border: 1px solid #f6f6f6;
      }
    }
  }
}

::ng-deep .mat-mdc-table .mat-mdc-header-cell .resize-holder {
  cursor: col-resize;
  position: absolute;
  top: 0;
  right: 0;
  height: 55px;
  border: 1px solid;
}

.edit-workflow-container {
  input[readonly] {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.mat-mdc-no-data-row {
  height: 5rem !important;
  text-align: center !important;
}

mat-sidenav.pr1-filter-mat-sidenav {
  width: 250px !important;

  .pr-check-box {
    position: relative;
    top: -10px;
    left: -10px;
  }
}

.user-management {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-mdc-form-field-wrapper {
    margin: 14px 0 0 0 !important;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-mdc-form-field-infix {
    line-height: 16px;
  }

  button {
    padding: 7px 20px 6px 20px;
    font-size: 16px;
    position: relative;
    top: -3px;
  }
}

.user-management-table {
  box-shadow: none !important;
}

.users-table .active-person,
.users-table .inactive-person {
  color: #bdbdbd !important;
}

.users-table {
  .mat-mdc-header-cell {
    font-size: 14px !important;
    font-weight: 500;
  }

  td {
    .mat-mdc-raised-button {
      text-align: left !important;
      box-shadow: none !important;
      width: 122px;
      background: none !important;
      font-size: 15px;

      .mat-icon {
        float: right;
        position: absolute;
        top: 10px;
        right: 4px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .user-management button {
    width: 100%;
    text-align: left;
  }

  .user-management .mat-mdc-form-field {
    width: 100%;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .user-management .mat-form-field-wrapper {
    margin: 14px 0 0 0 !important;
    padding: 0 !important;
  }

  .users-table table {
    table-layout: initial;
    width: 960px;
  }

  .users-table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .mat-mdc-paginator {
    width: 960px;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.active-dropdown .mdc-button__ripple {
  &::before {
    background-color: #1c7700 !important;
    width: 8px;
    height: 8px;
    content: "" !important;
    display: inline-block;
    border-radius: 50% !important;
    margin-right: 8px;
    opacity: 1 !important;
    position: relative !important;
    top: 7px !important;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.notactive-dropdown .mdc-button__ripple {
  &::before {
    width: 8px;
    height: 8px;
    background-color: #b00020 !important;
    content: "" !important;
    display: inline-block;
    border-radius: 50% !important;
    margin-right: 8px;
    opacity: 1 !important;
    position: relative !important;
    top: 7px !important;
  }
}

.user-status-toggle-button {
  padding-left: 0px !important;
}

.email-alerts-toggle-button {
  width: 10rem !important;
}

.list-types-section {
  width: 296px;
}

.list-types {
  ul {
    padding: 0;
    margin: 0;
  }
}

xng-breadcrumb {
  text-transform: capitalize !important;
}

mat-panel-title {
  font-weight: bold;
}

.mat-step-header[aria-label="current-active-step"] {
  background-color: #e3f7e5 !important;

  .mat-step-label {
    font-weight: bold !important;
  }

  .mat-step-icon-state-number {
    background-color: #1b5e20 !important;
  }
}

.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.drag-custom-placeholder {
  background: #ccc;
  height: 100%;
  width: 8rem;
  height: 6.75rem;
  border: dotted 3px #999;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.stage-drag {
  position: absolute !important;
  height: 100% !important;
  width: 80% !important;
  transform: translateZ(1000px) !important;
  transform: translateY(-100%) !important;
  z-index: 999999999999999 !important;
  left: 10% !important;
  top: 100% !important;

  &:active {
    cursor: grabbing !important;
  }

  &:hover {
    cursor: grab !important;
  }
}

.skip-drag-container {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  gap: 1rem !important;
}

.mark_complete_btn {
  background-color: white !important;
}

.stage_status_container {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: end;
  width: 100%;

  .completed-check-circle {
    color: #1c7700 !important;
  }

  p {
    position: relative !important;
    top: 10px !important;
    margin-left: 10px !important;
    font-weight: bold;
  }
}

.notification-banner {
  background: rgba(255, 249, 213, 1) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  height: 72px !important;
  padding-left: 1.2rem !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between;

  p {
    padding-top: 0.5rem !important;
    margin-left: 1rem !important;
  }

  .banner-actions {
    font-size: 14px;
    font-weight: 700;
    line-height: 21.23px;
    text-align: right;
    gap: 2rem !important;
    margin-right: 2rem !important;

    .banner-action-btn {
      cursor: pointer;
    }

    .banner-action-btn:hover {
      color: rgba(0, 0, 0, 0.399) !important;
    }
  }
}

.flex-row-align-center {
  display: flex !important;
  align-items: center !important;
}

.badge-dropdown {
  .mdc-list-item__primary-text {
    display: flex;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
      margin-right: 15px;
      object-fit: contain;
    }
  }
}

.mat-mdc-menu-submenu-icon {
  right: 0 !important;
}

.edit-offer-tags-popup {
  h2 {
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: 27.3px;
    border-bottom: 1px solid #e0e0e0;
    padding: 0 0 15px 0;
    color: $light-primary;
  }

  h3 {
    padding: 0 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .list {
    padding: 0 10px 5px 10px;
  }

  .mdc-evolution-chip-set .mdc-evolution-chip {
    background-color: #ecebe5;
  }

  .mat-mdc-chip-remove {
    opacity: 1;
  }

  .mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--trailing,
  .mat-mdc-raised-button>.mat-icon {
    color: #aa2213 !important;
  }

  .mdc-button.mat-unthemed.mat-mdc-button-base {
    background-color: #fff;
  }

  .headline {
    p {
      float: left;
      padding: 0;
      margin: 0;
    }
  }

  .list-btn {
    align-self: flex-end;

    button {
      background-color: #fff;
      color: $light-primary;
    }
  }

  .add-new-tags {
    width: 100%;
    display: block !important;
    border-top: 1px solid #e0e0e0;
    padding: 15px 0 0 10px;
    margin: 20px 0 0 0;

    h3 {
      margin: 0;
      padding: 0 0 15px 0;
    }

    .mat-mdc-form-field {
      width: 100%;
    }
  }

  .no-tags {
    padding: 0 10px;
  }

  .edit-offer-tags-popup-header {
    .close {
      float: right;
      color: rgb(0 0 0 / 60%);
      cursor: pointer;
    }
  }
}

.edit-offer-tags-popup-actions.mdc-card__actions {
  padding: 0;
  margin: 0 -10px;
}

app-notifications-feed {
  .mat-mdc-card-content {
    p {
      line-height: 1.3rem !important;
    }
  }
}

.group-invalid {
  .mat-mdc-text-field-wrapper {
    border-top: 3px solid crimson !important;
  }
}

.mat-expansion-panel-header {
  background-color: #fafafa !important;
}

.mat-expansion-indicator::after {
  margin-top: -7px !important;
}

.mat-expansion-panel-header.mat-expanded {
  .mat-expansion-indicator::after {
    margin-top: 0px !important;
  }
}
.mat-mdc-dialog-container .mdc-dialog__surface{
  height: auto !important;
  .mat-mdc-table-sticky {
     top: -24px !important;
  }
}
.sort-radio-button {
  margin-right: 20px;
  .mdc-radio{
    padding: 0 !important;
  }
}

.clone-paginator{
  .mat-mdc-form-field{
    padding-bottom: 0 !important;
  }
}
.align-middle{
  .mat-mdc-menu-item-text{
    display: flex;
    align-items: center;
  }
  svg{
    position: relative;
    top:-2px;
  }
  .mat-mdc-menu-submenu-icon{
    top: 5px;
  }
}

.mat-dropdown-menu{
  .mat-mdc-menu-item{
      .mat-icon{
        margin: 0 5px 0 0;
      }
  }
  .align-middle{
    margin-right: 15px;
  }
  &.mat-mdc-menu-panel{
    button.mat-mdc-menu-item{
      justify-content: left;
    }
  } 
}
.actions-bar-dropdown{
  &.mat-mdc-menu-panel button.mat-mdc-menu-item{
    width: 170px;
    justify-content: start;
    text-align: left;
    .mat-mdc-menu-item-text{
      font-weight: normal !important;
      text-transform: none !important;
    }
  }

}
.search-input-group{
  display: flex;
    width: 100%;
    border: 1px solid lch(0 0 0 / 0.38);
    margin: 8px 15px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    input::placeholder {
      color: #757575;
      opacity: 1; /* Firefox */
    }
    input{
      padding: 0 15px;
    }
    .mat-icon{
      color: #b4b4b4;
    }
}
.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled){
  background: #ECEBE5 !important;
}
.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__icon--trailing{
  color: #AA2213 !important;
  opacity: 1;
}
.pl-2{
  padding: 0 12px !important;
  top: -5px;
  position: relative;
}

.pr-input-white{
  .mat-mdc-text-field-wrapper{
    background-color: #fff;
  }
}