//TODO: theme this file
@use '@angular/material' as mat;
@import './variables.scss';


button {

    --button-font-family: inherit;
    --button-font-size: 1rem;
    --button-font-weight: 500;
    --button-line-height: 2rem;
    --button-border-radius: 4px;
    //--button-letter-spacing: .0892857143em;
    align-items: center;
    background: #F5F5F5; //rgba(0,0,0,0);
    border: 1px solid #5D5D5D;
    border-radius: var(--button-border-radius, 4px);
    box-sizing: border-box;
    color: #5D5D5D;
    cursor: pointer;
    display: inline-flex;
    font-family: var(--button-font-family, 1rem);
    font-size: var(--button-font-size, 1rem);
    font-weight: var(--button-font-weight, inherit);
    justify-content: center;
    //letter-spacing: var(--button-letter-spacing, .0892857143em);
    //line-height: var(--button-line-height, 2rem);
    //min-width: 64px;
    margin: 0;
    outline: none;
    overflow: visible;
    padding: 0.5rem 1rem;
    position: relative;
    user-select: none;
    vertical-align: middle;

    // align-items: center;
    // border: 1px solid #5D5D5D;
    // border-radius: 0.3125rem;
    // color: #5D5D5D;
    // display: flex;
    // font-size: 1rem;
    // height: 2.5rem;
    // padding-inline: 1rem;
    // position: relative;
    // box-shadow: 1px 1px 1px lightgray;

    svg {
        margin-right: 0.5rem;
    }

    &[basic] {
        background-color: inherit;
        border: none;
        color: #0041C2;
        font-size: 0.875rem;
        font-weight: bold;
        height: max-content;
        margin: 0;
        padding: 0;
        width: max-content;
    }


    // &[flat] {}

    &[raised] {
        &:not([color]) {
            background: #F5F5F5;
            border: none;
            box-shadow: 0px 2px 4px #00000029;
            color: #5D5D5D;
        }

        &[color='primary'] {
            background-color: #0041C2;
            border: none;
            box-shadow: 0px 2px 4px #00000029;
            color: #fff;
        }

        &[color='secondary'] {
            background-color: #ECE8D2;
            border: none;
            box-shadow: 0px 2px 4px #00000029;
            color: #000;
        }
    }

    &[stroked] {

        &:not([color]) {
            background: #F5F5F5;
            color: #5D5D5D;
            border: 1px solid #5D5D5D;
        }

        &[color='primary'] {
            background-color: #012169;
            color: #fff;
            border: 1px solid #fff;
        }

        &[color='secondary'] {
            background-color: #ECE8D2;
            color: #000;
            border: 1px solid #000;
        }
    }

}