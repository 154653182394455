//TODO theme this file

.offer {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: stretch;
    height: 100%;
    position: relative;

    .offer-image {
        padding: .75rem;
        justify-content: center;
        display: flex;
        max-height: 13.25rem;
        // margin-bottom: 1rem;
        flex: 1;

        .main-img {
            max-width: 100%;
            max-height: 13.25rem;
            // margin: auto;
        }

        .badge {
            width: 50px;
            height: 50px;
            object-fit: contain;
            left: 0;
            position: absolute;
            top: 0;
        }

        .add-to-list {
            color: #616161;
            filter: invert(38%) sepia(6%) saturate(19%) hue-rotate(17deg) brightness(94%) contrast(85%);
            height: 1.25rem;
            margin: 3px 3px 0 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .head-line {
        align-items: stretch;
        flex: 1;
        font-size: 0.875rem;
        font-weight: bold;
        line-height: 1.25rem;
    }

    .price-line {
        color: #0041c2;
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 1.25rem;

        sup {
            font-size: 0.75rem;
            line-height: 0;
            position: relative;
            top: 0.125rem;
            vertical-align: super;
        }
    }

    .retail-price-line {
        font-size: 0.875rem;
        margin-bottom: 0.25rem;

        sup {
            font-size: 0.5rem;
            line-height: 0;
            position: relative;
            top: 0.125rem;
            vertical-align: super;
        }
    }

    button {
        margin-top: auto;
    }

    // button {
    //     align-items: center;
    //     background: none;
    //     border: none;
    //     border-radius: 0;
    //     box-shadow: none;
    //     color: #012169;
    //     cursor: pointer;
    //     height: auto;
    //     padding: 0;
    //     margin: 0;
    // }
}

.cover-img {
    width: 50vw;
    min-height: 50%;
    margin: auto !important;

    .main-img {
        object-fit: cover;
        width: 100%;
    }

    .offer-image {
        video {
            max-width: 50vw;
        }
    }
}

.fill-img {
    width: 50vw;
    max-height: calc(100vh - 300px);
    margin: auto !important;

    .imageTemplate {
        max-width: 60vw;
        height: calc(100vh - 300px);

        .main-img {
            width: 100%;
            height: 100%;
            object-fit: fill;
            display: block;
        }
    }

    .offer-image {
        margin-bottom: unset !important;
        padding: 0px !important;

        a {
            width: 100%;
            height: calc(100vh - 300px);

            .main-img {
                width: 100% !important;
                height: 100% !important;
                object-fit: fill !important;
                display: block;
            }
        }
    }


    .offer-image {
        video {
            width: 100%;
            object-fit: fill;
            display: block;
        }
    }
}

.contain-img {
    width: 50vw;
    min-height: 50%;
    margin: auto !important;

    a {
        max-width: 1000px;
        height: 450px;

        .main-img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: block;
        }
    }

    .offer-image {
        video {
            max-width: 40vw;
            display: block;
        }
    }
}

.contain-img.align-left {
    .imageTemplate {
        display: flex;
        justify-content: left;
    }

    .videoTemplate {
        display: flex;
        justify-content: left;
    }
}

.contain-img.align-center {
    .imageTemplate {
        display: flex;
        justify-content: center;
    }

    .videoTemplate {
        display: flex;
        justify-content: center;
    }
}

.contain-img.align-right {
    .imageTemplate {
        display: flex;
        justify-content: right;
    }

    .videoTemplate {
        display: flex;
        justify-content: right;
    }
}

.contain-img.align-left {
    .offer-image {
        justify-content: left;

        .main-img {
            margin: unset !important;
        }
    }

    .offer-image:has(video) {
        justify-content: left;
    }
}

.contain-img.align-center {
    .offer-image {
        justify-content: center;

        .main-img {
            margin: unset !important;
        }
    }

    .offer-image:has(video) {
        justify-content: center;
    }
}

.contain-img.align-right {
    .offer-image {
        justify-content: right;

        .main-img {
            margin: unset !important;
        }
    }

    .offer-image:has(video) {
        justify-content: right;
    }
}


.video-contain {
    height: 100%;
    max-height: calc(100vh - 300px);
    max-width: 60vw;
    display: flex;
}

.video-contain video {
    object-fit: contain;
    height: 100%;
    max-height: calc(100vh - 300px);
    border: none;
    box-shadow: none;
    display: block;
}

.video-fill video {
    object-fit: fill;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 300px);
    border: none;
    box-shadow: none;
    display: block;
}

.align-left {
    justify-content: left;
}

.align-center {
    justify-content: center;
}

.align-right {
    justify-content: right;
}